import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
// Material
import Typography from "@material-ui/core/Typography";
// Icons
import CloseIcon from "@material-ui/icons/Close";
import NotListedLocation from "@material-ui/icons/NotListedLocation";
import AddLocation from "@material-ui/icons/AddLocation";
import CancelIcon from "@material-ui/icons/Cancel";
import AddShoppingCart from "@material-ui/icons/AddShoppingCart";
import DeliveryDining from "../../assets/svg-components/DeliveryDining";
// import NoFoodIcon from "../../assets/svg-components/NoFood";
// Material Component
import Modal from "../../components/Material/Modal/Modal";
import Btn from "../../components/Controls/Button/Button";
// Contants
import { ERROR_TYPES } from "../../utils/Constants";
// Styles
import useStyles from "./style.js";
import clsx from "clsx";
import IconBtn from "../Controls/IconButton/IconButton";
import { dispatchResetErrorMessage } from "../../utils/Shared";

function Error({ type, title, description }) {
  const classes = useStyles();

  const isDefaultError = useMemo(() => {
    return type === ERROR_TYPES.DEFAULT;
  }, [type]);
  const isResturantError = useMemo(() => {
    return type === ERROR_TYPES.RESTURANT;
  }, [type]);
  const isInValidLocationError = useMemo(() => {
    return type === ERROR_TYPES.INVALID_LOCATION;
  }, [type]);
  const isMinOrderError = useMemo(() => {
    return type === ERROR_TYPES.MIN_AMOUNT_ORDER;
  }, [type]);

  return (
    <div className={classes.errorContainer}>
      <IconBtn
        aria-label="close"
        component="span"
        icon={<CloseIcon />}
        onClick={() => {
          dispatchResetErrorMessage();
        }}
        className={classes.closeButton}
      />
      <div className={classes.iconSection}>
        {isDefaultError && <CancelIcon className={classes.icon} />}
        {isResturantError && <CancelIcon className={classes.icon} />}
        {isInValidLocationError && (
          <NotListedLocation className={classes.icon} />
        )}
        {isMinOrderError && <DeliveryDining className={classes.icon} />}
      </div>
      <div className={classes.detailSection}>
        <Typography className={classes.title} variant="h2" noWrap>
          {title}
        </Typography>
        <Typography className={classes.desc} variant="body1">
          {description}
        </Typography>
      </div>
    </div>
  );
}

function Wrap() {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const hotel = useSelector((state) => state.hotel);
  const error = useSelector((state) => state.error);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    setShowModal(error.show);
  }, [error]);

  const isDefaultError = useMemo(() => {
    return error.type === ERROR_TYPES.DEFAULT;
  }, [error.type]);
  const isResturantError = useMemo(() => {
    return error.type === ERROR_TYPES.RESTURANT;
  }, [error.type]);
  const isInValidLocationError = useMemo(() => {
    return error.type === ERROR_TYPES.INVALID_LOCATION;
  }, [error.type]);
  const isMinOrderError = useMemo(() => {
    return error.type === ERROR_TYPES.MIN_AMOUNT_ORDER;
  }, [error.type]);

  return (
    <>
      <Modal
        makeBackGroundBlack={isResturantError}
        open={showModal && error.showAs === "dialog"}
        customView={true}
        modelContainer={classes.errorModalContainer}
        mainContainerClass={clsx(isResturantError && classes.errorBgColor)}
      >
        <div>
          <Error
            type={error.type}
            title={error.title}
            description={error.message}
          />
          {isDefaultError && <></>}
          {isResturantError && <></>}
          {isInValidLocationError && (
            <div className={classes.actionContainer}>
              <Btn
                handleClick={() => {
                  setShowModal(false);
                  if (user) {
                    history.push(`/${hotel.hotelKey}/profile`);
                  }
                }}
                text={"Change location"}
                startIcon={<AddLocation />}
              />
            </div>
          )}
          {isMinOrderError && (
            <>
              <div className={classes.actionContainer}>
                <Btn
                  handleClick={() => {
                    if (hotel?.hotelKey) {
                      history.push(`/${hotel.hotelKey}`);
                      setShowModal(false);
                    }
                  }}
                  text={"Add more items"}
                  startIcon={<AddShoppingCart />}
                />
              </div>
              {/* <div className={classes.actionContainer}>
                <Link
                  component="button"
                  variant="primary"
                  onClick={() => setShowModal(false)}
                  className={classes.link}
                >
                  {"Proceed to checkout >>"}
                </Link>
              </div> */}
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default Wrap;
