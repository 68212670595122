import { combineReducers } from "redux";

//Reducers
import user from "../User/UserReducer";
import loader from "../Loader/LoaderReducer";
import snackbar from "../Snackbar/SnackbarReducer";
import theme from "../Theme/ThemeReducer";
import hotel from "../Restaurant/RestaurantReducer";
import menu from "../Menu/MenuReducer";
import cart from "../Cart/CartReducer";
import order from "../Order/OrderReducer";
import location from "../Location/LocationReducer";
import error from "../Error/ErrorReducer";
import takeyLocation from "../Takey/Location/LocationReducer";
import takeyRestaurant from "../Takey/Restaurant/RestaurantReducer";
import currentTakeyCuisines from "../Takey/CurrentCuisine/CurrentCuisineReducer";
import home from "../Home/HomeReducer";
import app from "../App/AppReducer";
import time from "../Time/TimeReducer";
import modal from "../Modal/ModalReducer";
import voucher from "../Voucher/VoucherReducer";
import checkout from "../Checkout/CheckoutReducer";

export default combineReducers({
  user,
  loader,
  snackbar,
  theme,
  hotel,
  menu,
  cart,
  order,
  location,
  error,
  takeyLocation,
  takeyRestaurant,
  currentTakeyCuisines,
  home,
  app,
  time,
  modal,
  voucher,
  checkout,
});
