import {
  axiosInstance,
  axiosInstanceNode,
  axiosInstanceSocket,
  axiosInstanceSocketV2,
} from "../../network/apis";
import { API } from "./../../utils/Constants";

export const getRestaurant = async (hotelKey) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { data } = await axiosInstance.post(
    `${API.ENDPOINTS.GET_RESTAURANT}`,
    { id: `${hotelKey}`, timeZone: `${timeZone}` },
    { handlerEnabled: false }
  );
  if (data.status === false) {
    throw new Error(data.message);
  }
  return { data: data.data, update: data?.update, appInfo: data?.appinfo };
};

export const getTableDetails = async (tableId) => {
  const { data } = await axiosInstance.get(
    // `${API.ENDPOINTS.GET_TABLE_DETAILS}${tableId}`,
    `${API.ENDPOINTS.GET_TABLE_DETAILS}?tabId=${tableId}`
    // { handlerEnabled: false }
  );
  if (data.status === false) {
    throw new Error(data.message);
  }
  return { data: data.data };
};

export const tableOptionRequest = async (details) => {
  const { data } = await axiosInstanceSocket.post(
    `${API.ENDPOINTS.TABLE_OPTION_REQUEST}`,
    { ...details },
    { handlerEnabled: false }
  );
  if (data.status === false) {
    throw new Error(data.message);
  }
  return data;
};

export const tableOptionRequestV2 = async (details) => {
  const { data } = await axiosInstanceSocketV2.post(
    `${API.ENDPOINTS.TABLE_OPTION_REQUEST}`,
    { ...details },
    { handlerEnabled: false }
  );
  if (data.status === false) {
    throw new Error(data.message);
  }
  return data;
};

export const getRestaurantTables = async ({ hotelId }) => {
  const { data } = await axiosInstance.get(
    // `${API.ENDPOINTS.GET_TABLE_DETAILS}${tableId}`,
    `${API.ENDPOINTS.ONLINE_TABLES}?id=${hotelId}`
    // { handlerEnabled: false }
  );
  if (!data?.data) {
    throw new Error(data.message);
  }
  return { data: data.data };
};
